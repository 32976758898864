import React from 'react'
import styled from 'styled-components'
import {useStaticQuery, graphql} from "gatsby";
import { navigate } from 'gatsby'
import headerLogo from '../../../static/images/logo/logo.svg'
import MedadButton from '../library/Button'


import rightImg from '../../../static/images/404/images/404.png'
import Button from "../library/Button";

const NotFound = () => {

    const {strapiNotFoundPage} = useStaticQuery(query);

    return (
        <NotFoundStyle>
            <Left>
                <TextWrapper>
                    <H3>Sorry, we couldn’t find that page</H3>
                    <P>We have shifted a few things around, and your page must have gotten lost. Try retyping the
                        address or just head back to our home page.</P>
                    <MedadButton className={'margin'} primary type={'button'} label={'Go to Homepage'} onClick={() => navigate('/')}/>
                </TextWrapper>
            </Left>
            <Right/>
        </NotFoundStyle>
    )
}

const NotFoundStyle = styled.div`
  min-height: calc(100vh - 92px);
  padding: 0 12rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  align-content: center;
  margin-top: -30px;;

  .margin {
    margin-top: 4rem;
  }

  @media ${({theme}) => theme.breakpoints.mobile} {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 0;
    padding: 0 2.4rem;
  }
`
const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-self: center;
  @media ${({theme}) => theme.breakpoints.mobile} {
    margin-top: 2.4rem;
    margin-bottom: 4.2rem;
  }
`

const TextWrapper = styled.div`

`

const Right = styled.div`
  width: 100%;
  background-image: url(${rightImg});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 50vh;
  @media ${({theme}) => theme.breakpoints.mobile} {
    background-size: cover;
    min-height: 40vh;
  }
`

const H3 = styled.div`
  max-width: 37.1rem;
  font-family: Sequel75;
  font-size: 3.2rem;
  line-height: 4.4rem;
  @media ${({theme}) => theme.breakpoints.mobile} {
    text-align: left;
    max-width: unset;
  }
`

const P = styled.p`
  max-width: 43.1rem;
  margin-top: 4rem;
  font-family: Sequel45;
  font-size: 2rem;
  @media ${({theme}) => theme.breakpoints.mobile} {
    text-align: left;
    max-width: unset;
  }
`

export default NotFound

const query = graphql`
    query {
        strapiNotFoundPage {
            title
            subtitle
            button
        }
    }
`;
