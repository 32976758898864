import {graphql, useStaticQuery} from "gatsby";
import React from 'react'

import NotFound from "../components/not-found/Not-Found.js";
import Layout from '../components/layout/Layout'
import Seo from '../components/seo'

const NotFoundPage = (props) => {

  const {strapiNotFoundPage} = useStaticQuery(query);

  return (
      <Layout path={props.location.pathname}>
        <Seo
            title={strapiNotFoundPage.metaTitle}
            description={strapiNotFoundPage.metaDescription}
            keywords={strapiNotFoundPage.metaKeywords}
        />
        <NotFound />
      </Layout>
  )
}

export default NotFoundPage

const query = graphql`
    query {
        strapiNotFoundPage {
            metaTitle
            metaDescription
            metaKeywords
        }
    }
`;
